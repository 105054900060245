.fade-in {
  animation: fade-in 2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out{  
  animation: fade-out 2s;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.elementToFadeInAndOut {
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards;
}

@-webkit-keyframes fadeinout {
0%,100% { opacity: 0; }
50% { opacity: 1; }
}

@keyframes fadeinout {
0%,100% { opacity: 0; }
50% { opacity: 1; }
}

html, body, #root {
  max-width: 100%;
  overflow-x: hidden;
}

